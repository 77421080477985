const variants = ['azure-free', 'aws-free', 'on-premise-pro', 'cloud-community'];

export interface PathInfos {
  variant: string;
  version?: string;
}

export function getPathInfos(): PathInfos {
  const [, variant, version] = location.pathname.split('/');

  if (variant && variants.includes(variant)) {
    if (version && /^\d+\.\d+\.\d+$/.test(version)) {
      return { variant, version };
    }

    return { variant };
  }

  return { variant: '' };
}
