import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { getPathInfos } from './utils';

function getBasename() {
  const infos = getPathInfos();

  if (infos.variant) {
    if (infos.version) {
      return `/${infos.variant}/${infos.version}`;
    }

    return `/${infos.variant}`;
  }

  return undefined;
}

export default ({ children }) => <BrowserRouter basename={getBasename()}>{children}</BrowserRouter>;
