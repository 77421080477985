
import Footer from "../../../../../pages/page-docs/src/Footer.tsx";
import Header from "../defaults/Header.tsx";
import Logo from "../../../../../pages/page-docs/src/Logo.tsx";
import InfoBar from "../../../../../pages/page-docs/src/InfoBar.tsx";
import Breadcrumbs from "../defaults/Breadcrumbs.tsx";
import SectionNav from "../defaults/SectionNav.tsx";

const brandName = "Piral Cloud";
const languages = [].map(s => ({
  ...s,
  select() {
    location.href = '/' + s.code;
  },
}));

export { Footer, Header, Logo, InfoBar, Breadcrumbs, SectionNav, brandName, languages };
