import type { Sitemap } from '@pidoc/core';
import { getPathInfos } from './utils';

export default function filter(sitemap: Sitemap) {
  const { variant } = getPathInfos();

  if (variant !== '') {
    for (const key of Object.keys(sitemap)) {
      const chapter = sitemap[key];

      for (let j = chapter.sections.length; j--; ) {
        const section = chapter.sections[j];

        for (let i = section.links.length; i--; ) {
          const link = section.links[i];
          const target = link.meta?.products || '(all)';

          if (target !== '(all)' && !target.split(',').includes(variant)) {
            section.links.splice(i, 1);
          }
        }

        if (section.links.length === 0) {
          chapter.sections.splice(j, 1);
        }
      }

      if (chapter.sections.length === 0) {
        delete sitemap[key];
      }
    }
  }

  return sitemap;
}
