
      import filterSitemap from "../../../../../pages/page-docs/src/filter.ts";

      
import { lazy } from 'react';

function populate(source) {
  const results = [];

  source.forEach(item => {
    const title = item.section;
    const [result] = results.filter(m => m.title === title);

    if (result) {
      result.links.push(item);
    } else {
      results.push({
        title,
        links: [item],
      });
    }
  });

  return results;
}


      export default filterSitemap({ "general": {
      "title": "General",
      "sections": [...populate([
  {
    id: "01-introduction",
    route: "/general/01-introduction",
    title: "Introduction and Overview",
    link: "/general/01-introduction",
    section: "Introduction",
    meta: {"title":"Introduction and Overview","description":"Find out about the most important features the Piral Feed Service is offering.","products":"(all)","audience":"Everyone","level":"Beginner","section":"Introduction"},
    page: lazy(() => import('../tools/__generated__/features-01-introduction.en.tsx')),
  }, 
  {
    id: "02-terminology",
    route: "/general/02-terminology",
    title: "Terminology",
    link: "/general/02-terminology",
    section: "Introduction",
    meta: {"title":"Terminology","description":"A set of terms used across the documentation.","products":"(all)","audience":"Everyone","level":"Beginner","section":"Introduction"},
    page: lazy(() => import('../tools/__generated__/features-02-terminology.en.tsx')),
  }, 
  {
    id: "03-feed-management",
    route: "/general/03-feed-management",
    title: "Feed Management",
    link: "/general/03-feed-management",
    section: "Features",
    meta: {"title":"Feed Management","description":"Read out how different feeds of micro frontends can be managed.","products":"(all)","audience":"Everyone","level":"Beginner","section":"Features"},
    page: lazy(() => import('../tools/__generated__/features-03-feed-management.en.tsx')),
  }, 
  {
    id: "04-micro-frontend-management",
    route: "/general/04-micro-frontend-management",
    title: "Micro Frontend Management",
    link: "/general/04-micro-frontend-management",
    section: "Features",
    meta: {"title":"Micro Frontend Management","description":"Find out how the Piral Feed Service allows you to do micro frontend management.","products":"(all)","audience":"Everyone","level":"Beginner","section":"Features"},
    page: lazy(() => import('../tools/__generated__/features-04-micro-frontend-management.en.tsx')),
  }, 
  {
    id: "05-api-key-management",
    route: "/general/05-api-key-management",
    title: "API Key Management",
    link: "/general/05-api-key-management",
    section: "Features",
    meta: {"title":"API Key Management","description":"Find out how API key management works to securely give machines and developers access to certain functionality.","products":"(all)","audience":"Everyone","level":"Beginner","section":"Features"},
    page: lazy(() => import('../tools/__generated__/features-05-api-key-management.en.tsx')),
  }, 
  {
    id: "06-rule-management",
    route: "/general/06-rule-management",
    title: "Rule Management",
    link: "/general/06-rule-management",
    section: "Features",
    meta: {"title":"Rule Management","description":"Find out about what feature flags and rules can do for your pilets.","products":"(all)","audience":"Everyone","level":"Beginner","section":"Features"},
    page: lazy(() => import('../tools/__generated__/features-06-rule-management.en.tsx')),
  }, 
  {
    id: "07-user-management",
    route: "/general/07-user-management",
    title: "User Management",
    link: "/general/07-user-management",
    section: "Features",
    meta: {"title":"User Management","description":"Find out how user management works with the Piral Feed Service.","products":"on-premise-pro","audience":"Everyone","level":"Beginner","section":"Features"},
    page: lazy(() => import('../tools/__generated__/features-07-user-management.en.tsx')),
  }, 
  {
    id: "08-entity-management",
    route: "/general/08-entity-management",
    title: "Entity Management",
    link: "/general/08-entity-management",
    section: "Features",
    meta: {"title":"Entity Management","description":"Learn about entities and their management in the Piral Feed Service.","products":"on-premise-pro","audience":"Everyone","level":"Beginner","section":"Features"},
    page: lazy(() => import('../tools/__generated__/features-08-entity-management.en.tsx')),
  }, 
  {
    id: "09-config-management",
    route: "/general/09-config-management",
    title: "Config Management",
    link: "/general/09-config-management",
    section: "Features",
    meta: {"title":"Config Management","description":"Find out what you can with config management for pilets.","products":"on-premise-pro","audience":"Everyone","level":"Beginner","section":"Features"},
    page: lazy(() => import('../tools/__generated__/features-09-config-management.en.tsx')),
  }]), ...populate([
  {
    id: "CHANGELOG",
    route: "/general/CHANGELOG",
    title: "Changelog",
    link: "/general/CHANGELOG",
    section: "Releases",
    meta: {"title":"Changelog","description":"Details for all the releases.","products":"(all)","audience":"Everyone","level":"Beginner","section":"Releases"},
    page: lazy(() => import('../tools/__generated__/releases-CHANGELOG.en.tsx')),
  }, 
  {
    id: "ROADMAP",
    route: "/general/ROADMAP",
    title: "Roadmap",
    link: "/general/ROADMAP",
    section: "Releases",
    meta: {"title":"Roadmap","description":"Ideas for upcoming releases.","products":"(all)","audience":"Everyone","level":"Beginner","section":"Releases"},
    page: lazy(() => import('../tools/__generated__/releases-ROADMAP.en.tsx')),
  }])],
    },"tutorials": {
      "title": "Tutorials",
      "sections": [...populate([
  {
    id: "01-introduction",
    route: "/tutorials/01-introduction",
    title: "Introduction",
    link: "/tutorials/01-introduction",
    section: "Getting Started",
    meta: {"title":"Introduction","description":"Find out what Piral Feed Service Pro is about. Learn more about the structure of our tutorials.","products":"(all)","audience":"Everyone","level":"Beginner","section":"Getting Started"},
    page: lazy(() => import('../tools/__generated__/tutorials-01-introduction.en.tsx')),
  }, 
  {
    id: "02-creating-a-feed",
    route: "/tutorials/02-creating-a-feed",
    title: "Creating a Feed",
    link: "/tutorials/02-creating-a-feed",
    section: "Getting Started",
    meta: {"title":"Creating a Feed","description":"Find out how to create and manage a feed of pilets.","products":"(all)","audience":"Everyone","level":"Beginner","section":"Getting Started"},
    page: lazy(() => import('../tools/__generated__/tutorials-02-creating-a-feed.en.tsx')),
  }, 
  {
    id: "03-publishing-a-pilet-via-cli",
    route: "/tutorials/03-publishing-a-pilet-via-cli",
    title: "Publishing with the Piral CLI",
    link: "/tutorials/03-publishing-a-pilet-via-cli",
    section: "Publish",
    meta: {"title":"Publishing with the Piral CLI","description":"Find out how to publish a pilet to a feed using the Piral CLI.","products":"(all)","audience":"Everyone","level":"Beginner","section":"Publish"},
    page: lazy(() => import('../tools/__generated__/tutorials-03-publishing-a-pilet-via-cli.en.tsx')),
  }, 
  {
    id: "04-publishing-a-pilet-via-portal",
    route: "/tutorials/04-publishing-a-pilet-via-portal",
    title: "Publishing via the Portal",
    link: "/tutorials/04-publishing-a-pilet-via-portal",
    section: "Publish",
    meta: {"title":"Publishing via the Portal","description":"Find out how to publish a pilet to a feed using the Piral Feed Service administration portal.","products":"(all)","audience":"Everyone","level":"Beginner","section":"Publish"},
    page: lazy(() => import('../tools/__generated__/tutorials-04-publishing-a-pilet-via-portal.en.tsx')),
  }, 
  {
    id: "05-publishing-a-pilet-via-api",
    route: "/tutorials/05-publishing-a-pilet-via-api",
    title: "Publishing with the Service API",
    link: "/tutorials/05-publishing-a-pilet-via-api",
    section: "Publish",
    meta: {"title":"Publishing with the Service API","description":"Find out how to publish a pilet to a feed using the Piral Feed Service API.","products":"(all)","audience":"Everyone","level":"Beginner","section":"Publish"},
    page: lazy(() => import('../tools/__generated__/tutorials-05-publishing-a-pilet-via-api.en.tsx')),
  }, 
  {
    id: "05-publishing-module-federation",
    route: "/tutorials/05-publishing-module-federation",
    title: "Publishing a Module Federation Remote",
    link: "/tutorials/05-publishing-module-federation",
    section: "Publish",
    meta: {"title":"Publishing a Module Federation Remote","description":"Find out how to publish a Webpack Module Federation remote to a feed using the Piral Feed Service API.","products":"(all)","audience":"Everyone","level":"Beginner","section":"Publish"},
    page: lazy(() => import('../tools/__generated__/tutorials-05-publishing-module-federation.en.tsx')),
  }, 
  {
    id: "05-publishing-native-federation",
    route: "/tutorials/05-publishing-native-federation",
    title: "Publishing a Native Federation Remote",
    link: "/tutorials/05-publishing-native-federation",
    section: "Publish",
    meta: {"title":"Publishing a Native Federation Remote","description":"Find out how to publish a native federation remote to a feed using the Piral Feed Service API.","products":"(all)","audience":"Everyone","level":"Beginner","section":"Publish"},
    page: lazy(() => import('../tools/__generated__/tutorials-05-publishing-native-federation.en.tsx')),
  }, 
  {
    id: "05-publishing-vanilla",
    route: "/tutorials/05-publishing-vanilla",
    title: "Publishing a Vanilla Javascript Micro Frontend",
    link: "/tutorials/05-publishing-vanilla",
    section: "Publish",
    meta: {"title":"Publishing a Vanilla Javascript Micro Frontend","description":"Find out how to publish a vanilla javascript micro frontend to a feed using the Piral Feed Service API.","products":"(all)","audience":"Everyone","level":"Beginner","section":"Publish"},
    page: lazy(() => import('../tools/__generated__/tutorials-05-publishing-vanilla.en.tsx')),
  }, 
  {
    id: "05-using-the-api",
    route: "/tutorials/05-using-the-api",
    title: "Using the Service API",
    link: "/tutorials/05-using-the-api",
    section: "Details",
    meta: {"title":"Using the Service API","description":"Find out how to use the Piral Feed Service API for automatic various tasks.","products":"(all)","audience":"Everyone","level":"Beginner","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-05-using-the-api.en.tsx')),
  }, 
  {
    id: "06-adding-custom-config-to-a-pilet",
    route: "/tutorials/06-adding-custom-config-to-a-pilet",
    title: "Adding a Custom Config",
    link: "/tutorials/06-adding-custom-config-to-a-pilet",
    section: "Details",
    meta: {"title":"Adding a Custom Config","description":"Find out how to add some defined custom config information to a pilet.","products":"(all)","audience":"Everyone","level":"Beginner","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-06-adding-custom-config-to-a-pilet.en.tsx')),
  }, 
  {
    id: "07-defining-rules-to-a-pilet",
    route: "/tutorials/07-defining-rules-to-a-pilet",
    title: "Defining Rules",
    link: "/tutorials/07-defining-rules-to-a-pilet",
    section: "Details",
    meta: {"title":"Defining Rules","description":"Find out how to define rules for provisioning a pilet.","products":"(all)","audience":"Everyone","level":"Beginner","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-07-defining-rules-to-a-pilet.en.tsx')),
  }, 
  {
    id: "07-feature-flags",
    route: "/tutorials/07-feature-flags",
    title: "Feature Flags",
    link: "/tutorials/07-feature-flags",
    section: "Details",
    meta: {"title":"Feature Flags","description":"Find out how to use Piral Feed Service as a feature flags engine.","products":"(all)","audience":"Everyone","level":"Beginner","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-07-feature-flags.en.tsx')),
  }, 
  {
    id: "07a-pilet-metadata",
    route: "/tutorials/07a-pilet-metadata",
    title: "Pilet Metadata",
    link: "/tutorials/07a-pilet-metadata",
    section: "Details",
    meta: {"title":"Pilet Metadata","description":"Details on how pilets can set configuration and rules when publishing.","products":"(all)","audience":"Everyone","level":"Intermediate","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-07a-pilet-metadata.en.tsx')),
  }, 
  {
    id: "08-publish-a-preview-version-of-a-pilet",
    route: "/tutorials/08-publish-a-preview-version-of-a-pilet",
    title: "Publishing Preview Versions",
    link: "/tutorials/08-publish-a-preview-version-of-a-pilet",
    section: "Details",
    meta: {"title":"Publishing Preview Versions","description":"Find out how to publish a preview version of a micro frontend.","products":"(all)","audience":"Everyone","level":"Beginner","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-08-publish-a-preview-version-of-a-pilet.en.tsx')),
  }, 
  {
    id: "09-attaching-a-source-feed",
    route: "/tutorials/09-attaching-a-source-feed",
    title: "Attaching Feed Sources",
    link: "/tutorials/09-attaching-a-source-feed",
    section: "Details",
    meta: {"title":"Attaching Feed Sources","description":"Find out how to attach one feed of micro frontends to another feed.","products":"(all)","audience":"Everyone","level":"Beginner","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-09-attaching-a-source-feed.en.tsx')),
  }, 
  {
    id: "10-inviting-contributors",
    route: "/tutorials/10-inviting-contributors",
    title: "Inviting Contributors",
    link: "/tutorials/10-inviting-contributors",
    section: "Details",
    meta: {"title":"Inviting Contributors","description":"Find out how to invite contributors to a feed.","products":"(all)","audience":"Everyone","level":"Beginner","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-10-inviting-contributors.en.tsx')),
  }, 
  {
    id: "11-setup-of-authentication",
    route: "/tutorials/11-setup-of-authentication",
    title: "User Management",
    link: "/tutorials/11-setup-of-authentication",
    section: "Features",
    meta: {"title":"User Management","description":"Find out how user management works with the Piral Feed Service.","products":"on-premise-pro","audience":"Everyone","level":"Beginner","section":"Features"},
    page: lazy(() => import('../tools/__generated__/tutorials-11-setup-of-authentication.en.tsx')),
  }, 
  {
    id: "12-custom-domain",
    route: "/tutorials/12-custom-domain",
    title: "Custom Domains",
    link: "/tutorials/12-custom-domain",
    section: "Features",
    meta: {"title":"Custom Domains","description":"Introduce custom domains for your feeds in the Piral Feed Service.","products":"cloud-community,on-premise-pro","audience":"Everyone","level":"Beginner","section":"Features"},
    page: lazy(() => import('../tools/__generated__/tutorials-12-custom-domain.en.tsx')),
  }, 
  {
    id: "13-static-website",
    route: "/tutorials/13-static-website",
    title: "Static Website",
    link: "/tutorials/13-static-website",
    section: "Features",
    meta: {"title":"Static Website","description":"Serve a static website from your feed in the Piral Feed Service.","products":"cloud-community,on-premise-pro","audience":"Everyone","level":"Beginner","section":"Features"},
    page: lazy(() => import('../tools/__generated__/tutorials-13-static-website.en.tsx')),
  }, 
  {
    id: "14-security",
    route: "/tutorials/14-security",
    title: "Security Model",
    link: "/tutorials/14-security",
    section: "Details",
    meta: {"title":"Security Model","description":"Learn about the integrated security and authorization model.","products":"(all)","audience":"Everyone","level":"Intermediate","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-14-security.en.tsx')),
  }, 
  {
    id: "15-feed-tags",
    route: "/tutorials/15-feed-tags",
    title: "Feed Tags",
    link: "/tutorials/15-feed-tags",
    section: "Features",
    meta: {"title":"Feed Tags","description":"Learn about how tags work with your feeds.","products":"(all)","audience":"Everyone","level":"Intermediate","section":"Features"},
    page: lazy(() => import('../tools/__generated__/tutorials-15-feed-tags.en.tsx')),
  }, 
  {
    id: "16-managing-entities",
    route: "/tutorials/16-managing-entities",
    title: "Managing Entities",
    link: "/tutorials/16-managing-entities",
    section: "Features",
    meta: {"title":"Managing Entities","description":"Find out how to create and manage entities in a feed of micro frontends.","products":"(all)","audience":"Everyone","level":"Intermediate","section":"Features"},
    page: lazy(() => import('../tools/__generated__/tutorials-16-managing-entities.en.tsx')),
  }, 
  {
    id: "17-custom-rule-definitions",
    route: "/tutorials/17-custom-rule-definitions",
    title: "Custom Rule Definitions",
    link: "/tutorials/17-custom-rule-definitions",
    section: "Features",
    meta: {"title":"Custom Rule Definitions","description":"Find out how to add your own rule definitions to the service.","products":"on-premise-pro","audience":"Everyone","level":"Intermediate","section":"Features"},
    page: lazy(() => import('../tools/__generated__/tutorials-17-custom-rule-definitions.en.tsx')),
  }, 
  {
    id: "50-deploy-with-helm",
    route: "/tutorials/50-deploy-with-helm",
    title: "Deployment to a Kubernetes Cluster with Helm",
    link: "/tutorials/50-deploy-with-helm",
    section: "Setup and Configuration",
    meta: {"title":"Deployment to a Kubernetes Cluster with Helm","description":"Find out how to deploy the Piral Feed Service to a Kubernetes Cluster using Helm","products":"(all)","audience":"Everyone","level":"Beginner","section":"Setup and Configuration"},
    page: lazy(() => import('../tools/__generated__/tutorials-50-deploy-with-helm.en.tsx')),
  }])],
    },"setup": {
      "title": "Setup",
      "sections": [...populate([
  {
    id: "01-prerequisites",
    route: "/setup/01-prerequisites",
    title: "Prerequisites",
    link: "/setup/01-prerequisites",
    section: "Preparation",
    meta: {"title":"Prerequisites","description":"Prerequisites for running the Piral Feed Service as a Docker image.","products":"on-premise-pro","audience":"DevOps","level":"Beginner","section":"Preparation"},
    page: lazy(() => import('../tools/__generated__/setup-01-prerequisites.en.tsx')),
  }, 
  {
    id: "02-installation",
    route: "/setup/02-installation",
    title: "Installation",
    link: "/setup/02-installation",
    section: "Running",
    meta: {"title":"Installation","description":"Installation to have the Piral Feed Service running as a Docker image.","products":"on-premise-pro","audience":"DevOps","level":"Beginner","section":"Running"},
    page: lazy(() => import('../tools/__generated__/setup-02-installation.en.tsx')),
  }, 
  {
    id: "03-configuration-free",
    route: "/setup/03-configuration-free",
    title: "Configuration (Free)",
    link: "/setup/03-configuration-free",
    section: "Running",
    meta: {"title":"Configuration (Free)","description":"Configuration of the Piral Feed Service Docker image (Free version).","products":"azure-free,aws-free","audience":"DevOps","level":"Beginner","section":"Running"},
    page: lazy(() => import('../tools/__generated__/setup-03-configuration-free.en.tsx')),
  }, 
  {
    id: "03-configuration-pro",
    route: "/setup/03-configuration-pro",
    title: "Configuration (Pro)",
    link: "/setup/03-configuration-pro",
    section: "Running",
    meta: {"title":"Configuration (Pro)","description":"Configuration of the Piral Feed Service Docker image (Pro version).","products":"on-premise-pro","audience":"DevOps","level":"Beginner","section":"Running"},
    page: lazy(() => import('../tools/__generated__/setup-03-configuration-pro.en.tsx')),
  }, 
  {
    id: "04-aci",
    route: "/setup/04-aci",
    title: "Azure Container Instances",
    link: "/setup/04-aci",
    section: "Running",
    meta: {"title":"Azure Container Instances","description":"Running the Piral Feed Service Docker image on Azure Container Instances.","products":"on-premise-pro","audience":"DevOps","level":"Intermediate","section":"Running"},
    page: lazy(() => import('../tools/__generated__/setup-04-aci.en.tsx')),
  }, 
  {
    id: "05-provision-aws-service",
    route: "/setup/05-provision-aws-service",
    title: "AWS Marketplace Offering",
    link: "/setup/05-provision-aws-service",
    section: "Running",
    meta: {"title":"AWS Marketplace Offering","description":"Provisioning the Piral Feed Service using the AWS Marketplace offering.","products":"aws-free","audience":"DevOps","level":"Intermediate","section":"Running"},
    page: lazy(() => import('../tools/__generated__/setup-05-provision-aws-service.en.tsx')),
  }, 
  {
    id: "05-provision-azure-service",
    route: "/setup/05-provision-azure-service",
    title: "Azure Marketplace Offering",
    link: "/setup/05-provision-azure-service",
    section: "Running",
    meta: {"title":"Azure Marketplace Offering","description":"Provisioning the Piral Feed Service using the Azure Marketplace Offerings.","products":"azure-free","audience":"DevOps","level":"Intermediate","section":"Running"},
    page: lazy(() => import('../tools/__generated__/setup-05-provision-azure-service.en.tsx')),
  }, 
  {
    id: "06-kubernetes",
    route: "/setup/06-kubernetes",
    title: "Kubernetes",
    link: "/setup/06-kubernetes",
    section: "Running",
    meta: {"title":"Kubernetes","description":"Running the feed service in a Kubernetes cluster.","products":"on-premise-pro","audience":"DevOps","level":"Beginner","section":"Running"},
    page: lazy(() => import('../tools/__generated__/setup-06-kubernetes.en.tsx')),
  }, 
  {
    id: "11-azure-ad",
    route: "/setup/11-azure-ad",
    title: "Integration of Azure AD",
    link: "/setup/11-azure-ad",
    section: "Identity",
    meta: {"title":"Integration of Azure AD","description":"Including Azure AD as the identity provider for the Piral Feed Service.","products":"on-premise-pro","audience":"DevOps","level":"Intermediate","section":"Identity"},
    page: lazy(() => import('../tools/__generated__/setup-11-azure-ad.en.tsx')),
  }, 
  {
    id: "12-auth0",
    route: "/setup/12-auth0",
    title: "Integration of Auth0",
    link: "/setup/12-auth0",
    section: "Identity",
    meta: {"title":"Integration of Auth0","description":"Including Auth0 as the identity provider for the Piral Feed Service.","products":"on-premise-pro","audience":"DevOps","level":"Intermediate","section":"Identity"},
    page: lazy(() => import('../tools/__generated__/setup-12-auth0.en.tsx')),
  }, 
  {
    id: "13-ldap",
    route: "/setup/13-ldap",
    title: "Integration of LDAP",
    link: "/setup/13-ldap",
    section: "Identity",
    meta: {"title":"Integration of LDAP","description":"Including LDAP as the identity source for the Piral Feed Service.","products":"on-premise-pro","audience":"DevOps","level":"Intermediate","section":"Identity"},
    page: lazy(() => import('../tools/__generated__/setup-13-ldap.en.tsx')),
  }, 
  {
    id: "14-saml",
    route: "/setup/14-saml",
    title: "Integration of SAML",
    link: "/setup/14-saml",
    section: "Identity",
    meta: {"title":"Integration of SAML","description":"Including a SAML IdP for the Piral Feed Service.","products":"on-premise-pro","audience":"DevOps","level":"Intermediate","section":"Identity"},
    page: lazy(() => import('../tools/__generated__/setup-14-saml.en.tsx')),
  }, 
  {
    id: "21-storage",
    route: "/setup/21-storage",
    title: "Integration of Azure Blob Storage",
    link: "/setup/21-storage",
    section: "Storage",
    meta: {"title":"Integration of Azure Blob Storage","description":"Including Azure Blob Storage as file system provider for the Piral Feed Service.","products":"on-premise-pro","audience":"DevOps","level":"Intermediate","section":"Storage"},
    page: lazy(() => import('../tools/__generated__/setup-21-storage.en.tsx')),
  }, 
  {
    id: "31-planetscale",
    route: "/setup/31-planetscale",
    title: "Integration of PlanetScale",
    link: "/setup/31-planetscale",
    section: "Database",
    meta: {"title":"Integration of PlanetScale","description":"Including PlanetScale as database provider for the Piral Feed Service.","products":"on-premise-pro","audience":"DevOps","level":"Intermediate","section":"Database"},
    page: lazy(() => import('../tools/__generated__/setup-31-planetscale.en.tsx')),
  }])],
    },"faq": {
      "title": "FAQ",
      "sections": [...populate([
  {
    id: "01-general",
    route: "/faq/01-general",
    title: "General",
    link: "/faq/01-general",
    section: "Questions",
    meta: {"title":"General","description":"Frequently asked questions about the Piral Feed Service.","products":"(all)","audience":"Everyone","section":"Questions"},
    page: lazy(() => import('../tools/__generated__/questions-01-general.en.tsx')),
  }, 
  {
    id: "02-docker",
    route: "/faq/02-docker",
    title: "Docker Image",
    link: "/faq/02-docker",
    section: "Questions",
    meta: {"title":"Docker Image","description":"Frequently asked questions about the Docker image.","products":"on-premise-pro","audience":"Everyone","section":"Questions"},
    page: lazy(() => import('../tools/__generated__/questions-02-docker.en.tsx')),
  }, 
  {
    id: "03-license",
    route: "/faq/03-license",
    title: "License",
    link: "/faq/03-license",
    section: "Questions",
    meta: {"title":"License","description":"Frequently asked questions about the license.","products":"on-premise-pro","audience":"Everyone","section":"Questions"},
    page: lazy(() => import('../tools/__generated__/questions-03-license.en.tsx')),
  }])],
    } });
    