import * as React from 'react';
import { PageFooter, FooterMenu, FooterLink } from '@smapiot/components';
import './footer.scss';

const companyName = 'smapiot GmbH';
const year = new Date().getFullYear().toString();
const copyright = `© ${year} ${companyName}. All rights reserved.`;
const portalBase = 'https://portal.piral.cloud';
const legal = [
  {
    name: 'Imprint',
    link: `${portalBase}/imprint`,
  },
  {
    name: 'Data Privacy',
    link: `${portalBase}/privacy`,
  },
  {
    name: 'Terms and Conditions',
    link: `${portalBase}/terms`,
  },
];
const piral = [
  {
    name: 'Cloud Services',
    link: 'https://www.piral.cloud',
  },
  {
    link: 'https://www.piral.io',
    name: 'Framework',
  },
  {
    link: 'https://status.piral.io',
    name: 'Server Status',
  },
];

export default () => (
  <PageFooter
    copyrightHtml={
      <>
        {copyright} <a href={`${portalBase}/status`}>Status</a>
      </>
    }
    left={
      <FooterMenu title="Piral Universe">
        {piral.map(l => (
          <FooterLink key={l.link} url={l.link} target="_blank">
            {l.name}
          </FooterLink>
        ))}
      </FooterMenu>
    }
    right={
      <FooterMenu title="Legal">
        {legal.map(l => (
          <FooterLink key={l.link} url={l.link}>
            {l.name}
          </FooterLink>
        ))}
      </FooterMenu>
    }></PageFooter>
);
