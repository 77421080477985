import * as React from 'react';
import './infobar.scss';
import { getPathInfos } from './utils';

const products = [
  {
    id: '',
    name: '(All)',
    icon: 'fas fa-asterisk',
  },
  {
    id: 'azure-free',
    name: 'Piral on Azure Free',
    icon: 'fab fa-windows',
  },
  {
    id: 'aws-free',
    name: 'Piral on AWS Free',
    icon: 'fab fa-aws',
  },
  {
    id: 'on-premise-pro',
    name: 'Piral on Premises Pro',
    icon: 'fab fa-docker',
  },
  {
    id: 'cloud-community',
    name: 'Piral Cloud Community',
    icon: 'fas fa-cloud',
  },
];

export default () => {
  const { variant } = getPathInfos();
  const product = products.find(p => p.id === variant);

  return (
    <div className="info-container">
      <div className="layout-container">
        <div className="drop-container">
          <span>
            <strong className="drop-label">
              <i className={product.icon} /> {product.name}
            </strong>
            <i className="fas fa-caret-down" aria-hidden="true" />
          </span>
          <div className="drop">
            <ul>
              {products
                .filter(p => p.id !== variant)
                .map(p => (
                  <li key={p.id}>
                    <a href={`${location.origin}/${p.id}`}>
                      <i className={p.icon} /> {p.name}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
